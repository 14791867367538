import { ReactElement } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MobileNav } from "./MobileNav";
import { Footer } from "./Footer";
import { DesktopNav } from "./DesktopNav";

type Props = {
  children: ReactElement;
};

export function Layout({ children }: Props): ReactElement {
  return (
    <Router>
      <DesktopNav />
      <MobileNav />
      {children}
      <Footer />
    </Router>
  );
}
