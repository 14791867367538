import { ReactElement, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { RxCaretDown } from "react-icons/rx";

import { logoIcon, logoText } from "../../assets/images";
import { PublicRoutes } from "../../types/Routes";

export const DesktopNav = (): ReactElement => {
  const [isAboutDropdownVisible, setAboutDropdownVisible] = useState(false);
  const [isServicesDropdownVisible, setServicesDropdownVisible] = useState(false);

  const aboutRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      aboutRef.current &&
      !aboutRef.current.contains(event.target as Node) &&
      isAboutDropdownVisible
    ) {
      setAboutDropdownVisible(false);
    }
    if (
      servicesRef.current &&
      !servicesRef.current.contains(event.target as Node) &&
      isServicesDropdownVisible
    ) {
      setServicesDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const toggleAboutDropdown = () => {
    setAboutDropdownVisible(!isAboutDropdownVisible);
  };

  const toggleServicesDropdown = () => {
    setServicesDropdownVisible(!isServicesDropdownVisible);
  };

  const handleDropdownLinkClick = () => {
    setAboutDropdownVisible(false);
    setServicesDropdownVisible(false);
  };

  return (
    <div className="flex-row justify-between w-80% mx-auto hidden xl:flex py-4">
      <div className="flex flex-row items-center gap-3">
        <img src={logoIcon} alt="Eazipower Logo Icon" className="w-8" />
        <img src={logoText} alt="Eazipower Logo Text" className="w-32" />
      </div>
      <div className="flex items-center justify-around w-1/2 font-semibold leading-17.73px text-sm text-brand-gray">
        <Link to={PublicRoutes.HOME} className="py-30px">
          Home
        </Link>
        <div className="relative" ref={aboutRef}>
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleAboutDropdown}
          >
            <span className="py-30px">About</span>
            <RxCaretDown size="24px" />
          </div>
          {isAboutDropdownVisible && (
            <div className="absolute z-10 left-0 top-12 mt-2 w-56 bg-white border border-gray-200 shadow-lg rounded-lg">
              <Link
                to={PublicRoutes.ABOUT}
                onClick={handleDropdownLinkClick}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                About Eazipower
              </Link>
              <Link
                to={PublicRoutes.NEWS}
                onClick={handleDropdownLinkClick}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                News
              </Link>
              <Link
                to={PublicRoutes.TEAMS}
                onClick={handleDropdownLinkClick}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Teams
              </Link>
            </div>
          )}
        </div>
        <Link to={PublicRoutes.SHOP} className="py-30px">
          Shop
        </Link>
        <div className="relative" ref={servicesRef}>
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleServicesDropdown}
          >
            <span className="py-30px">Services</span>
            <RxCaretDown size="24px" />
          </div>
          {isServicesDropdownVisible && (
            <div className="absolute z-10 left-0 top-12 mt-2 w-56 bg-white border border-gray-200 shadow-lg rounded-lg">
              <Link
                to={PublicRoutes.DONATIONS}
                onClick={handleDropdownLinkClick}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Donations
              </Link>
              <Link
                to={PublicRoutes.BECOME_A_DISTRIBUTOR}
                onClick={handleDropdownLinkClick}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Become a Distributor
              </Link>
              <Link
                to={PublicRoutes.TRACK_ORDER}
                onClick={handleDropdownLinkClick}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Track an Order
              </Link>
            </div>
          )}
        </div>
        <Link to={PublicRoutes.PRICING} className="py-30px">
          Pricing
        </Link>
        <Link to={PublicRoutes.CONTACT} className="py-30px">
          Contact
        </Link>
      </div>
      <div className="flex items-center">
        <Link
          to={PublicRoutes.BECOME_A_DISTRIBUTOR}
          onClick={handleDropdownLinkClick}
          className="px-6 py-3 text-white rounded-lg bg-brand-yellow text-13px"
        >
          Launch Application
        </Link>
      </div>
    </div>
  );
};
