import { ReactElement, useEffect } from "react";
import AppRouter from "../../routes/AppRouter";
import Aos from "aos";
import "aos/dist/aos.css";

export const AppProvider = (): ReactElement => {
  useEffect(() => {
    Aos.init({
      delay: 100,
    });
    Aos.refresh();
  }, []);
  return (
    <>
      <AppRouter />
    </>
  );
};
