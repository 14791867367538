import { ReactElement } from "react";
import { Circles } from "react-loader-spinner";

export function PageLoading(): ReactElement {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <Circles
        height="150"
        width="150"
        color="#FFA300"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}
